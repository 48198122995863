import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.resize();
  }

  change() {
    this.resize();
  }

  resize() {
    if(!this.isSizeToSelected()) { return }

    var tmpSelect = document.createElement('select');
    tmpSelect.innerHTML = "<option>" + $(this.element).find('option:selected').text() + "</option>"
    $(tmpSelect).css("display: none");
    $(tmpSelect).insertAfter(this.element);
    $(this.element).width($(tmpSelect).width());
    $(tmpSelect).remove();
  }

  isSizeToSelected() {
    // TODO: Would be nice to have just a generic way of checking data- attributes that are
    //       boolean, where the standard is that they are 'true' if they are explicitly set
    //       to something truthy or even just there and set to nothing, and they are 'false'
    //       if explicitly set to something falsey or not there.
    var sizeToSelected = $(this.element).data('size_to_selected')
    if (sizeToSelected === '') return true
    return sizeToSelected
  }
}
