import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    ajax: Boolean,
    searchInputComponent: String,
    query: { type: Object, default: {} }
  }

  connect() {
    var url = $(this.element).data("url");
    var lookup = $(this.element).data("lookup");
    var fields = $(this.element).data("fields")?.toString();
    var option_value = $(this.element).data("option-value");
    var attribute = $(this.element).data("attribute");
    var min_input_length = $(this.element).data("min-input-length");
    let self = this;

    let select2_options = {
      theme: "decoded",
      placeholder: $(this.element).data("placeholder"),
      minimumInputLength: min_input_length,
      // If we need to have more control over these templates, consider rendering the
      // HTML in our ViewComponent and using that here.
      templateResult: function (d) {

        if (d.loading) {
          return d.text;
        }

        let term = self.queryValue.term || '';
        let $result = self.markMatch(d.text, term);

        var displayElem = '<span>' + $result
        if (d.highlight) {
          // TODO: We might want to move some/all of this to something the LookupConfig classes
          //       ultimately control. They might even be able to handle the entire result format.
          displayElem += '<div class="search-highlight">' + d.highlight + '</div>'
        }
        displayElem += '</span>'
        return $(displayElem);
      },
      templateSelection: function (d) {
        return $('<span>' + d.text + '</span>');
      },
      language: {
        searching: function (params) {
          self.queryValue = params;

          return 'Searching…';
        }
      },
      closeOnSelect: false,
      // TODO: this is here to make it easier to configure, but upon review, we might decide the best
      //       path is to update the theme file directly.
      dropdownCssClass: 'decoded-checkbox-selection',
      // NOTE: this setting requires the custom patch in the view_components_select2 bundle
      dropdownPosition: $(this.element).data('dropdown-position'),
      dropdownParent: $(this.element).parent()
    }

    if (this.ajaxValue) {
      select2_options['ajax'] = {
        url: url,
          delay: 100, // Brief delay for workaround and reducing requests
            data: function (params) {
              return {
                q: params.term,
                lookup: lookup,
                fields: fields,
                attribute: attribute
              };
            },
        processResults: function (data) {
          return {
            results: data.map(function (i) {
              return { id: `${i[option_value]}`, text: i[attribute], highlight: i['highlight'] };
            })
          };
        }
      }
    }

    $(this.element).select2(select2_options);

    // Allow the sidebar to stay scrollable when the select2 results dropdown opens
    // Take from https://forums.select2.org/t/scrolling-prevented-on-page/1851
    $(this.element).on('select2:open', function (e) {
      const evt = "scroll.select2";
      $(e.target).parents().off(evt);
      $(window).off(evt);
    });

    // No real need to ever close the select2 since it itself is wrapped in a dropdown that actually does need to open and close.
    // Though this was added for the change to allow no minimum input length for searching.
    $(this.element).on('select2:closing select2:close', function (e) {
      e.preventDefault();
      e.stopPropagation();
    });

    this.configureSearchInput();
  }

  // Wraps the matching text of a result in a span for styling.
  // Taken from https://stackoverflow.com/questions/28958620/using-select2-4-0-0-with-infinite-data-and-filter#29018243
   markMatch (text, term) {
    // Find where the match is
    // Normalize the text https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
    // and then remove any combining diacritical marks (accents) from the text so that we can do a normalized search.
    // Otherwise the code ends up in an error path and no options get rendered
    // e.g. looking up "pfi" would return "PFİZER PFE İLAÇLARI A.Ş.". But it would error out without normalizing
    var match = text.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(term.toUpperCase());
    var $result = document.createElement("span");

    // If there is no match, move on
    if (match < 0) {
      return text;
    }

    // Put in whatever text is before the match
    // $result.text(text.substring(0, match));
    $result.innerHTML = text.substring(0, match);

    // Mark the match
    var $match = $('<span class="select2-rendered__match"></span>');
    $match.text(text.substring(match, match + term.length));

    var $match = document.createElement("span");
    $match.className = "select2-rendered__match"
    $match.innerHTML = text.substring(match, match + term.length);

    // Append the matching text
    $result.append($match);

    // Put in whatever is after the match
    $result.append(text.substring(match + term.length));

    return $result.innerHTML;
  }

  // Change the select2 multiselect search input to match our dbp-search-input component.
  // Classes and data attributes are copied over to the search input to create the desired
  // functionality. Hopefully in the future, we can build our own lookup component that doesn't
  // rely on select2 to make this easier and clearer to manage.
  configureSearchInput() {
    const searchInput = $(this.element).next().find('.select2-search__field')

    // We need to reformat the search input to match the provided component
    // instead of completely replacing it. This is to maintain search functionality,
    // as replacing it completely breaks that due to event listeners being orphaned.
    if (this.searchInputComponentValue && searchInput.length) {
      const searchInputComponent = this.searchInputComponentValue;
      const parsedHtml = $($.parseHTML(searchInputComponent)[0]);
      const wrapperClasses = parsedHtml[0].className
      const inputClasses = parsedHtml.find('input')[0].className
      const leftIconContainer = parsedHtml.find('.input-icon-container--left');
      const rightIconContainer = parsedHtml.find('.input-icon-container--right');
      const searchWrapper = $(this.element).next().find('.select2-search');

      // Helper function to copy data attributes from one element to another
      function copyDataAttributes(sourceElement, targetElement) {
        Object.entries(sourceElement.dataset).forEach(([key, value]) => {
          targetElement.dataset[key] = value;
        });
      }

      // Add wrapper classes and data attributes
      searchWrapper.addClass(wrapperClasses)
      copyDataAttributes(parsedHtml[0], searchWrapper[0]);

      // Add input classes and data attributes
      searchInput.addClass(inputClasses);
      copyDataAttributes(parsedHtml.find('input')[0], searchWrapper.find('input')[0]);

      // Insert icon containers
      if (leftIconContainer.length) { searchInput.after(leftIconContainer); }
      if (rightIconContainer.length) { searchInput.after(rightIconContainer); }

      // Finally, to cleanup the DOM and hide our magic, remove the data value!
      this.element.removeAttribute('data-lookup-component-search-input-component-value');
    }
  }
}
