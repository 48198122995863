import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="plus--side-navigation"
export default class extends Controller {
  static targets = ["toggle"]
  static values = {
    active: {type: Boolean, default: true},
    initialized: {type: Boolean, default: false},
    stateKey: String
  }

  initialize() {
    // Even though the initiating class is added in the component's template, we're going to ensure it's here just in case
    $(this.element).addClass("initiating")
    this.setActiveStateFromStorage()
  }

  connect() {
    // Wrapping things here in a try finally statement to make sure no matter what happens, we clear our initiating class
    // and set our initialized value to true so that the side nav is in its bare minimum working state, and the user is not 
    // stuck in some weird state
    try {
      this.setUpResizeObserver()
      window.addEventListener('resize', this.setMainContentWidth.bind(this))
    } finally {
      this.initializedValue = true
      $(this.element).removeClass("initiating")
    }
  }

  disconnect() {
    this.tearDownResizeObserver()
    window.removeEventListener('resize', this.setMainContentWidth.bind(this))
  }

  toggle() {
    this.activeValue = !this.activeValue
    this.storeActiveState()
  }

  collapseOnClick(event) {
    if (this.activeValue) {
      let targetHref = event.currentTarget.href
      let useTurbo = event.currentTarget.dataset.turbo === "true" || event.currentTarget.dataset.turboPrefetch === "true"

      $(this.element).one("transitionend", () => {
        // Another method we could use here is Turbo.visit(targetHref) We'll have to figure how well it will play
        // with the non turbo enabled pages first. But if we ever move the main content into a frame, this is a place
        // where we can revisit! One unknown issue if we were to use the Turbo.visit(targetHref) here with non-turbo
        // pages is that when we hover over a turbo-link, then went to a non-turbo link, it appears that the browser
        // might place the turbo-link into its history stack. So when we use the browser's native back button, we briefly
        // go see the page of the enabled turbo link before we land on the previous page that might've been a non turbo link

        // In regards to the above, this is implemented so when we do start using turbo, it should be an easier migration.
        // Right now, this is used by the "Other Tools" links in the side nav, which also don't prefetch content (which
        // I hope means the back navigation is unaffected?). I also haven't been able to replicate the above issue ^^
        if (useTurbo) {
          Turbo.visit(targetHref)
        } else {
          window.location = targetHref
        }
      })

      event.preventDefault()
    }

    this.activeValue = false
    this.storeActiveState()
  }

  // Specific action to detect if a click was on the side nav body and on nothing else before toggling
  bodyToggle(event) {
    if (event.target == this.element) this.toggle()
  }

  activeValueChanged(active, previousActive) {
    // We only are interested in running through this callback when we are not still in the initialization state,
    // or if our previous state was not defined
    if (!this.initializedValue || previousActive === "") return

    $(this.element).addClass("changingState")

    $(this.element).one("transitionend", this.transitionendHandler.bind(this))

    this.toggleCollapseState(active)
    this.setMainContentWidth()
  }

  transitionendHandler(event) {
    if (event.target === event.currentTarget) {
      $(this.element).removeClass("changingState")
    } else {
      $(this.element).one("transitionend", this.transitionendHandler.bind(this))
    }
  }

  toggleCollapseState(active) {
    if (active) {
      $(this.element).removeClass('collapsed')
    } else {
      $(this.element).addClass('collapsed')
    }
  }

  // Set the max-width of the main content based on the width of the side nav.
  // The main content will have to be in a single element, right after the side nav in the DOM.
  setMainContentWidth() {
    let side_nav_width = $(this.element).outerWidth()

    $(this.element).next().css('max-width', `calc(100vw - ${side_nav_width}px`)
  }

  remainHoverOnCollapsed(event) {
    if (!this.activeValue) {
      let parent = $(event.currentTarget).parent()

      // Remove hover state from everything else 
      $(this.element).find(".hover").removeClass("hover")

      parent.toggleClass('hover')

      // Remove the hover state class on click anywhere else
      // Stop propagation is to prevent the current click event that triggered remainHoverOnCollapsed
      // from triggering this new click event on bubble up 
      event.stopPropagation()
      $(window).one("click", () => {
        parent.removeClass('hover')
      })
    }
  }

  activeStateKey() {
    if (this.stateKeyValue) return `${this.stateKeyValue}__active`
  }

  setActiveStateFromStorage() {
    let storedActive = localStorage.getItem(this.activeStateKey())

    if (storedActive) {
      this.activeValue = storedActive
    } 
    else {
      this.storeActiveState()
    }
    // Typically, updating the this.activeValue would trigger the activeValueChanged callback, which technically already
    // calls this toggleCollapseState. But we want to set/unset the collapse state while we're still in the "initiating"
    // state. Otherwise, the activeValueChanged callback will run once we're out of our "initiating" state, which means
    // our CSS transitions will run everytime on load
    this.toggleCollapseState(this.activeValue)
  }

  storeActiveState() {
    if (this.activeStateKey()) localStorage.setItem(this.activeStateKey(), this.activeValue)
  }

  // Adding transitions to the side nav seems to mess with how the main content was getting calculated because the
  // setMainContentWidth is getting called before the side nav transition has ended and the new width is available
  setUpResizeObserver() {
    this.element.resizeObserver = new ResizeObserver((entries) => {
      this.setMainContentWidth()
    });
    this.element.resizeObserver.observe(this.element)
  }

  tearDownResizeObserver() {
    this.element.resizeObserver.disconnect();
  }
}
