import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "clear"]

  connect() {

  }

  clear() {
    this.inputTarget.value = "";
    this.element.classList.remove("can-clear");
  }

  // Sets or removes the "can-clear" class on the element based on whether the input has a value.
  // This class is used for styling (padding adjustement, hiding the button).
  setClearableState() {
    if (this.clearTarget) {
      if (this.inputTarget.value.length > 0) {
        this.element.classList.add("can-clear");
      } else {
        this.element.classList.remove("can-clear");
      }
    }
  }
}