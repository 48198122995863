import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    loadingSelectors: Array
  }

  connect() {
    // This could be setup as an action on the element, but this feels like less code writing
    // compared to attaching the action to all of the elements.
    this.element.querySelectorAll('a').forEach(link => {
      link.addEventListener('click', this.navigate.bind(this));
    });
  }

  disconnect() {
    this.element.querySelectorAll('a').forEach(link => {
      link.removeEventListener('click', this.navigate.bind(this));
    });
  }

  navigate() {
    this.applyLoadingState();
    this.disableControls();
  }

  // Apply the loading state to the elements that match the selectors.
  // This can be changed to a jQuery selector if we need it to be that.
  applyLoadingState() {
    this.loadingSelectorsValue.forEach(selector => {
      document.querySelectorAll(selector).forEach(element => {
        element.classList.add('loading');
      });
    });
  }

  // Disables the pagination controls to prevent multiple requests and
  // to help showcase soemthing is happening
  disableControls() {
    this.element.querySelectorAll('a').forEach(link => {
      link.classList.add('disabled');
    });
  }
}
