import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["remove"];
  static values = {
    key: Array,
    parentKey: Array
  }

  connect() {
    this.setToggleListener()
  }

  removeColumn() {
    let keyValue = JSON.stringify(this.keyValue)
    let parentKeyValue = JSON.stringify(this.parentKeyValue)

    if (this.hasParentKeyValue) {
      // Remove column data
      $(`td[data-column-key='${keyValue}']`).remove();
      // Remove nested column header
      var removed_column = $(`th[data-column-key='${keyValue}'][data-column-parent-key='${parentKeyValue}']`);
      var removed_column_width = removed_column.outerWidth()
      var nested_table = removed_column.closest("table")
      var main_table_th = nested_table.closest("th")
      removed_column.remove()
      var parent_th = nested_table.find(`th[data-column-key='${parentKeyValue}']:not([data-column-parent-key])`)
      var sibling_columns = nested_table.find(`th[data-column-parent-key='${parentKeyValue}']`);
      // If no more sibling headers with the same parent, we are assuming there are no more descendent columns
      if (sibling_columns.length == 0) {
        parent_th.parent().closest(`th[data-column-key='${parentKeyValue}']`).remove() // Remove th from main table
        $(`td[data-column-parent-key='${parentKeyValue}']`).remove() // Remove all associated td
        parent_th.remove();
      } else {
        parent_th.prop("colSpan", parent_th.prop("colSpan") - 1);
        nested_table.outerWidth(nested_table.outerWidth() - removed_column_width)
        main_table_th.outerWidth(main_table_th.outerWidth() - removed_column_width)
      }
    } else {
      // Remove all direct columns
      $(`[data-column-key='${keyValue}']`).remove();
      // Remove all descendent column
      $(`[data-column-parent-key='${keyValue}']`).remove();
      $(".current-column-count").text($(".connect-datatable th").length);
    }

    document.querySelector("#add-column-dropdown")["plus--add-column-dropdown--component"].updateActiveColumns();
  }

  // Setting the z-index in this way helps prevent clipping with the th to the right.
  // However, for some reason this has introduced a visual bug with the leftmost column.
  setToggleListener() {
    $(this.element).on('show.bs.dropdown', function() {
      $('.column-dropdown').parents("th").css("z-index", 1)
      $(this).parents("th").css("z-index", 2)
    });

    $(this.element).on('hidden.bs.dropdown', function() {
      $(this).parents("th").css("z-index", 1)
    });
  }
}
