// app/javascript/controllers/debounce_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "result", "resultItem", "searchbar", "typeInput", "commandPaletteKey", "preview", "sidebar", "initial", "inputIcons"]
  static values = {
    previewUrl: String,
    selectUrl: String,
    defaultState: String,
    searchUrl: String
  }

  connect() {
    Turbo.cache.exemptPageFromPreview() // if preview is enabled, sometimes an erroneous search preview will show

    this.metaKey = false
    let self = this
    let os = this.detectOS()

    if (os == "Mac OS") {
      this.metaKeyCode = "Meta"
    } else {
      this.metaKeyCode = "Control"
    }

    this.commandPaletteKeyTargets.forEach(element => {
      if (os == "Mac OS") {
        $(element).html("⌘ K")
      } else {
        $(element).html("Ctrl+K")
      }
    })

    document.addEventListener('keydown', (e) => {
      if (e.key == self.metaKeyCode ) {
        self.metaKey = true
      } else if (e.key == "k" && self.metaKey) {
        e.preventDefault()
        self.focus()
      }
    })

    document.addEventListener('keyup', (e) => {
      if (e.key == 'Meta') {
        self.metaKey = false
      }
    })

    document.addEventListener("click", (e) => {
      // Return if the element clicked is the search component
      if (self.element === e.target || self.element.contains(e.target)) {
        self.focus()
      } else {
        self.unfocus()
      }
    })

    if (this.hasDefaultStateValue && this.defaultStateValue == "focus") {
      this.focus()
    }
  }

  beforeSearch() {
    $(this.element).addClass("loading")
    $(this.element).removeClass("previewable")
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 500)

    if (this.searchbarTarget.value.length > 0) {
      $(this.inputIconsTarget).addClass('inputted')
    } else {
      $(this.inputIconsTarget).removeClass('inputted')
    }
  }

  afterSearch() {
    $(this.element).removeClass("loading")
  }

  clear() {
    $(this.searchbarTarget).val("")
    $(this.inputIconsTarget).removeClass('inputted')
    this.search()
  }

  filterSearch(event) {
    event.preventDefault()
    $(this.typeInputTarget).val($(event.currentTarget).data("type"))
    this.formTarget.requestSubmit()
  }

  resultTargetConnected(element) {
    let self = this

    $(this.element).on("keydown", function(event) {
      switch(event.key) {
        case "ArrowDown":
          event.preventDefault()
          self.navigateDown()
          break;
        case "ArrowUp":
          event.preventDefault()
          self.navigateUp()
          break;
        case "Enter":
          event.preventDefault()
          self.select()
          break;
      }
    })
  }

  resultItemTargetDisconnected(element) {
    if ($(element).hasClass("selected")) {
      // clear selected preview
      $(element).removeClass("selected")
      this.searchPreview()
    }
  }

  previewTargetConnected(element) {
    // Handle when there is something in the preview
    if ($(this.previewTarget).is(":not(:empty)")) {
      $(this.element).addClass("previewable")
      // For some reason the CSS transition animation will not run unless this is wrapped in a timeout
      setTimeout(() => {
        $(this.sidebarTarget).addClass("show")
      }, 0)
    }
  }

  previewTargetDisconnected(element) {
    // Handle when there is nothing in the preview
    if ($(this.previewTarget).is(":empty")) {
      $(this.element).removeClass("previewable")
      $(this.sidebarTarget).removeClass("show")
    }
  }

  focus() {
    $(this.element).addClass("focus")
    this.searchbarTarget.focus()
  }

  unfocus() {
    $(this.element).removeClass("focus")
  }

  navigateDown() {
    let currentSelectedIndex = this.selectedItemIndex()
    let newSelecedIndex = 0

    if (currentSelectedIndex < this.resultItemTargets.length - 1) {
      newSelecedIndex = currentSelectedIndex + 1
    } else if (currentSelectedIndex == this.resultItemTargets.length - 1) {
      this.clearSelectedItem()
      this.debouncePreview()

      return
    }

    this.clearSelectedItem()
    this.setSelectedItem(this.resultItemTargets[newSelecedIndex])
    this.resultItemTargets[newSelecedIndex].scrollIntoView({ block: "nearest" })
    this.debouncePreview()
  }

  navigateUp() {
    let currentSelectedIndex = this.selectedItemIndex()
    let newSelecedIndex = this.resultItemTargets.length - 1

    if (currentSelectedIndex > 0) {
      newSelecedIndex = currentSelectedIndex - 1
    } else if (currentSelectedIndex == 0) {
      this.clearSelectedItem()
      this.debouncePreview()

      return
    }

    this.clearSelectedItem()
    this.setSelectedItem(this.resultItemTargets[newSelecedIndex])
    this.resultItemTargets[newSelecedIndex].scrollIntoView({ block: "nearest" })
    this.debouncePreview()
  }

  select() {
    if (this.selectedItem()) {
      $(this.resultTarget).find(".result-info.selected")[0].click()
    } else {
      // This handles the un selected Enter key action. This might change later. Perhaps selecting first result? 
      this.results()
    }
  }

  results() {
    let typeParams = this.typeInputTargets
      .filter(t => t.value)
      .map(t => "type[]=" + t.value).join('&')
    let queryString = $(this.searchbarTarget).val()
    let location = this.searchUrlValue + "?q=" + queryString
    if(typeParams) { location += ("&" + typeParams) }
    gaPush("dbplus_event",
      {
        "ga_event_location": "search_results_query",
        "ga_text": queryString
      })
    this.unfocus()
    Turbo.visit(location);
  }

  searchPreview() {
    let selected = $(this.selectedItem())
    let body = {}
    let self = this

    if (selected.length > 0) {
      body = {
        identifier: selected.data("identifier"),
        kind: selected.data("kind")
      }
    }

    fetch(this.previewUrlValue, {
      method: 'POST',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return response.text();
    })
    .then(html => {
      Turbo.renderStreamMessage(html);
    })
    .catch(error => {
      // Handle error? Maybe show a message to the user?
    });
  }

  debouncePreview() {
    clearTimeout(this.previewTimeout)
    this.previewTimeout = setTimeout(() => {
      this.searchPreview()
    }, 300)
  }

  searchSelect(event) {
    // Save the selected search before redirecting
    let selected = $(this.selectedItem())

    let body = {
      identifier: selected.data("identifier"),
      kind: selected.data("kind")
    }

    fetch(this.selectUrlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    })
    .finally(() => {
      this.unfocus()
      Turbo.visit(selected[0].href)
    })

    event.preventDefault()
  }

  mouseOver(event) {
    if (event.currentTarget == this.selectedItem()) return

    this.clearSelectedItem()
    this.setSelectedItem(event.currentTarget)
    this.debouncePreview()
  }

  selectedItem() {
    return this.resultItemTargets.find((item) => $(item).hasClass("selected"))
  }

  selectedItemIndex() {
    return this.resultItemTargets.indexOf(this.selectedItem())
  }

  clearSelectedItem() {
    $(this.resultItemTargets).removeClass("selected")
  }

  setSelectedItem(item) {
    $(item).addClass("selected")
  }

  detectOS() {
    let platform = navigator?.userAgentData?.platform || navigator.platform,
        userAgent = navigator.userAgent,
        macosPlatforms = ["macOS", "Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS"
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS"
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows"
    } else if (/Android/.test(userAgent)) {
      os = "Android"
    } else if (/Linux/.test(platform)) {
      os = "Linux"
    }

    return os
  }
}
