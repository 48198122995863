import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  filterType
  filterName
  filterNameFull

  connect() {
    this.filterType = $(this.element).find(".filter-outer .filter-type").first().text()
    this.filterName = $(this.element).find(".filter-inner .filter-type").text()
    this.filterNameFull = this.filterType + " > " + this.filterName
  }

  toggleFilterCollapse() {
    $(this.element).find(".filter-body").slideToggle(0)
    $(this.element).toggleClass("collapsed")
  }

  closeFilter() {
    $(this.element).find(".interactive-element").prop("disabled", true);
    $(this.element).addClass("loading")
    $("table").addClass("loading")
  }
}
