import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle", "option"]
  default_text

  connect() {
    this.default_text = $(this.element).find(".button-label").text()
    this.stayOpen()

    // Only change initial label if the options have the following action
    // that changes the label (don't want to change label for other dropdowns)
    if ($(this.optionTargets).length > 0 && $(this.optionTarget).data("action", "action-dropdown#onValueChange").length) {
      this.setInitialState()
    }

    let self = this

    $(document).ready(function() {
      if ($(self.element).data("show") !== undefined && !$(self.element).hasClass("show")) {
        $(self.toggleTarget).dropdown("toggle")
      }
    })
  }

  stayOpen() {
    $(this.element).on('hide.bs.dropdown', (e) => {
      if ($(e.clickEvent?.target).parents('.dropdown-menu').length > 0) {
        e.preventDefault()
      }
    })
  }

  showLoader() {
    $(this.element).find("button").addClass("loading")
  }

  // When the selected value is changed, we want to display the selected value(s) in the dropdown button.
  // If the dropdown has a "select all" option, we also want that option to have specific behavior.
  updateDropdownState(event = null) {
    const allOption = $(this.element).find("input[value='all']");
    let allChecked = true; // not neccesarily true at first, but will remain true if all options are checked
    let hasValue = false;
    let newText = "";

    // Select all options if "all" is checked
    if (event && event.target.value === "all") {
      this.optionTargets.forEach(option => {
        option.checked = event.target.checked;
      });
    }

    // For each checked option, grab the text,
    // and if an option is unchecked, uncheck "all"
    this.optionTargets.forEach(option => {
      const $option = $(option);
      const text = $option.parent().text();
      const isChecked = option.checked;
      const isAllOption = option.value === "all";

      if (isChecked) {
        hasValue = true;
        if (!isAllOption) {
          newText += `${text}, `;
        }
      } else {
        if (!isAllOption) {
          allChecked = false; // If any option is unchecked, "all" should not be checked
        }
        if (isAllOption && event && !event.target.checked) {
          $option.prop("checked", false);
        }
      }
    });

    // Set the state of the "all" option if it exists
    if (allOption.length) {
      allOption.prop("checked", allChecked);
    }

    // Set the display text based on the selected options
    newText = hasValue ? newText.slice(0, -2) : this.default_text;
    $(this.element).find(".button-label").text(newText);

    // If triggered by radio input, close the dropdown
    if (event && event.target.getAttribute("type") == "radio") {
      $(this.element).find("button").dropdown("toggle");
    }
  }

  // Called when the dropdown is first loaded to set the initial state.
  // Should only be called if the dropdown options call "onValueChange" when chnaged.
  setInitialState() {
    this.updateDropdownState();
  }

  // Called when the selected value changes in the dropdown
  onValueChange(event) {
    this.updateDropdownState(event);
  }

  submitOnChange(event) {
    const form = event.target.closest("form");
    if (form) {
      form.requestSubmit();
    }
  }
}
