import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "filterRangeMulti", "filterRangeSingle"];

  connect() {

  }

  setFields() {
    let $select_val = $(this.selectTarget).val();
    let $multi = $(this.filterRangeMultiTarget)
    let $single = $(this.filterRangeSingleTarget)

    switch ($select_val) {
      case "in":
        $single.find(".filter-range-input").val("")
        $single.hide()
        $multi.find(".filter-range-input").val("")
        $multi.show()
        break

      case "empty":
        $multi.find(".filter-range-input").val("")
        $multi.hide()
        $single.find(".filter-range-input").val("")
        $single.hide()
        break

      default:
        $multi.find(".filter-range-input").val("")
        $multi.hide()
        $single.find(".filter-range-input").val("")
        $single.show()
    }
  }
}