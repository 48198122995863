import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['valueSummary', 'lookup']
  static values = {
    show: Boolean
  }

  initialize() {
    let self = this

    this.resizeObserverAttached = false
    this.scrollParent = $($(this.element).data("scroll-parent"))[0] || document

    this.select2OpenHandler = function() { self.opened = true }
    this.select2CloseHandler = function() { self.opened = false }
    this.select2SelectHandler = function() { self.scrollToSelected(); self.updateValueSummary(); }
    this.select2UnselectingHandler = function() { self.removing = true; }

    // For some reason, self.removing = false in here breaks functionality. For a long time, this
    // handler was set incorrectly (set on select2:unselected instead of select2:unselect), so
    // self.removing = false was never properly in this handler.
    this.select2UnselectHandler = function() { self.updateValueSummary(); }

    this.documentClickHandler = function(e) {
      if($(e.target).closest('.select2-wrapper, .select2-dropdown, .value-summary-container').length) { return }
      if(self.removing) { self.removing = false; return }

      $(self.lookupTarget).hide()
      $(self.valueSummaryTarget).parent().removeClass('active')

      self.updateValueSummary()
      self.dispatch("closed")
    }
    this.editValueClickHandler = function() {
      if (self.opened) {
        $(self.lookupTarget).hide();
        $(self.valueSummaryTarget).parent().removeClass('active');
        self.opened = false;

        self.updateValueSummary()
        self.dispatch("closed")
        return;
      }

      $(self.lookupTarget).show();
      $(self.valueSummaryTarget).parent().addClass('active')
      $(self.lookupTarget).find('select').select2('close')
      $(self.lookupTarget).find('select').select2('open') // this is for auto-opening the dropdown when the main filter dropdown is opened
      self.fixLookupPosition()
      $(self.lookupTarget).find('input').focus();

      if (!self.resizeObserverAttached) {
        // Observe both the header portion of the lookup (which adds selected values) and the
        // main lookup that shows the options. When one or both change size, we know we might
        // have to adjust for it.
        self.resizeObserver.observe($(self.lookupTarget).find('.select2-dropdown')[0])
        self.resizeObserver.observe($(self.lookupTarget).find('.select2-selection')[0])
        self.resizeObserverAttached = true
      }

      self.dispatch("opened", { detail: {
        afterCallback: () => self.fixLookupPosition(),
        element: self.element
      }})

      self.opened = true;
    }

    this.lookupPositionFixHandler = function() {
      self.fixLookupPosition()
    }

    this.resizeObserver = new ResizeObserver(function(elem) {
      // doing this within requestAnimationFrame avoids a potential infinite loop scenario
      requestAnimationFrame(() => self.maybeRequestHeight())
    })
  }

  connect() {
    this.opened = false
    this.removing = false

    var select2 = $(this.lookupTarget).find('select')

    $(this.valueSummaryTarget).parent().on('click', this.editValueClickHandler)
    select2.on('select2:open', this.select2OpenHandler);
    select2.on('select2:close', this.select2CloseHandler);
    select2.on('select2:unselecting', this.select2UnselectingHandler);
    select2.on('select2:unselect', this.select2UnselectHandler);
    select2.on('select2:select', this.select2SelectHandler);
    $(document).on('click', this.documentClickHandler);
    this.fixLookupPosition()
    $(this.scrollParent).on('scroll', this.lookupPositionFixHandler);

    if (this.showValue) {
      setTimeout(() => {
        // Triggering a click feels hacky, but it seemingly works. There was issues with
        // trying to open the select2 with code directly, so this is a workaround.
        $(this.valueSummaryTarget).parent().trigger('click')
      }, 0)
    }
  }

  disconnect() {
    var select2 = $(this.lookupTarget).find('select')

    $(this.valueSummaryTarget).parent().off('click', this.editValueClickHandler)
    select2.find('select').off('select2:open', this.select2OpenHandler);
    select2.find('select').off('select2:close', this.select2CloseHandler);
    select2.find('select').off('select2:unselecting', this.select2UnselectingHandler);
    select2.find('select').off('select2:unselect', this.select2UnselectHandler);
    select2.find('select').off('select2:select', this.select2SelectHandler);
    $(document).off('click', this.documentClickHandler);
    $(this.scrollParent).off('scroll', this.lookupPositionFixHandler);
    this.resizeObserver.disconnect()
  }

  dropdownHeight() {
    let dropdown = $(this.lookupTarget).find('.select2-dropdown')
    let selection = $(this.lookupTarget).find('.select2-selection')

    return dropdown.height() + selection.height()
  }

  // The purpose of this method is to send a message that extra height is needed if the
  // dropdown happens to be set to exclusively be 'below'. We can send negative heights
  // which we'd expect to only be used in cases where positive heights had already been
  // adjusted for.
  maybeRequestHeight() {
    const dropdownPosition = $(this.lookupTarget)
      .find('[data-controller="lookup-component"]')
      .data('dropdown-position')

    // TODO: We're sending much more information than we really need to here. All we really
    //       need is the extraHeightNeeded right now. However, there may be simpler ways
    //       to respond to these events using that extra information, so it's being kept
    //       here a while longer.
    if(dropdownPosition == 'below') {
      const absoluteLookupTargetY = $(this.lookupTarget).offset().top
      // We're always going to want to have a little space under the lookup. Plus
      // there are some other bits of spacing select2 seems to do that are difficult
      // to track down. This is a bit of a magic number, but it consistently gives
      // nice results. If you're up for a lot of potential frustration and have
      // nothing else pressing, feel free to try making it a little less magic.
      const extraPadding = 24
      const extraHeightNeeded = absoluteLookupTargetY + this.dropdownHeight() + extraPadding - $(document).height()

      this.dispatch("extraHeightNeeded", { detail: {
        afterCallback: () => this.fixLookupPosition(),
        element: this.element,
        extraHeightNeeded: extraHeightNeeded,
        dropdownHeight: this.dropdownHeight()
      }})
    }
  }

  fixLookupPosition() {
    let offset = $(this.valueSummaryTarget).parent().offset()
    offset.top += $(this.valueSummaryTarget).parent().outerHeight()
    offset.top += 4 // add a bit of a gap
    $(this.lookupTarget).offset(offset)
  }

  scrollToSelected() {
    let selected_choices = $(this.lookupTarget).find('.select2-selection__rendered')

    selected_choices.scrollTop(selected_choices[0].scrollHeight)
  }

  updateValueSummary() {
    var texts = $(this.lookupTarget).find('select').select2('data').map(x => x.text)
    if (texts.length === 0) { texts = ['select']}
    this.valueSummaryTarget.innerHTML = texts.join(', ')
  }
}
