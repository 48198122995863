// app/javascript/controllers/debounce_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["resultItem", "preview", "clear"]
  static values = {
    previewUrl: String,
  }

  connect() { 
    // With Turbo, if the user navigates back to a result page, the loading state may still be present
    this.element.classList.remove("loading")
  }

  searchPreview() {
    let selected = $(this.selectedItem())
    let body = {}
    let self = this

    if (selected.length > 0) {
      body = {
        identifier: selected.data("identifier"),
        kind: selected.data("kind")
      }
    }

    fetch(this.previewUrlValue, {
      method: 'POST',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return response.text();
    })
    .then(html => {
      Turbo.renderStreamMessage(html);
    })
    .catch(error => {
      // Handle error? Maybe show a message to the user?
    });
  }

  debouncePreview() {
    clearTimeout(this.previewTimeout)
    this.previewTimeout = setTimeout(() => {
      this.searchPreview()
    }, 300)
  }

  mouseOver(event) {
    if (event.currentTarget == this.selectedItem()) return

    this.clearSelectedItem()
    this.setSelectedItem(event.currentTarget)
    this.debouncePreview()
  }

  selectedItem() {
    return this.resultItemTargets.find((item) => $(item).hasClass("selected"))
  }

  selectedItemIndex() {
    return this.resultItemTargets.indexOf(this.selectedItem())
  }

  clearSelectedItem() {
    $(this.resultItemTargets).removeClass("selected")
  }

  setSelectedItem(item) {
    $(item).addClass("selected")
  }

  loading() {
    this.element.classList.add("loading")
  }

  clear(event) {
    event.preventDefault()
    this.loading()

    let url = this.clearTarget.href

    // Removing the headermakes things feel a bit smoother
    this.clearTarget.parentElement.remove();

    setTimeout(() => {
      Turbo.visit(url);
    }, 500);
  }
}
