import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="plus--side-navigation--link-group"
export default class extends Controller {
  static targets = ["details"]
  static values = {
    stateKey: String
  }

  // We are using a component to manage the state of the LinkGroupComponent. Would it be better if all this just lived
  // in the higher level side_navigation_controller? Perhaps. But, as of writing this, for some reason, unless something
  // was blatantly missing, adding the functions to the side_navigation_controller and setting a data-action on the details
  // element of this component to reference an action on the side_navigation_controller was not properly getting bubbled up
  // to the side_navigation_controller. This issue `might` be related to this issue Stimulus issue:
  // https://github.com/hotwired/stimulus/issues/363
  // So until that issue is resolved, this link_group_component will get its own controller to get things working
  connect() {
    this.setStateFromStorage()
  }

  // Manage the state of LinkGroupComponents. A stateKey will need to be set on the LinkGroupComponent to work
  namespacedStateKey() {
    if (this.stateKeyValue) return `drug_bank_plus-side-nav-state__${this.stateKeyValue}`
  }

  toggle() {
    this.storeState()
  }

  setStateFromStorage() {
    let storedState = localStorage.getItem(this.namespacedStateKey())

    if (storedState) {
      this.detailsTarget.open = storedState === "true"
    }
    else {
      this.storeState()
    }
  }

  storeState() {
    if (this.namespacedStateKey()) localStorage.setItem(this.namespacedStateKey(), this.detailsTarget.open)
  }
}
