import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "formError", "submit"];

  connect() {
    this.checkFormValidity()

    // Not all modals have a form, but all plus modals include this controller.
    // So check first for the form target before adding event listeners to prevent errors.
    if (this.formTarget.length) {
      this.formTarget.addEventListener("input", this.checkFormValidity.bind(this));
      this.formTarget.querySelectorAll('input[required]').forEach((input) => {
        input.addEventListener("blur", this.checkRequiredInput.bind(this));
      });
    }
  }

  disconnect() {
    if (this.formTarget.length) {
      this.formTarget.removeEventListener("input", this.checkFormValidity.bind(this));
      this.formTarget.querySelectorAll('input[required]').forEach((input) => {
        input.removeEventListener("blur", this.checkRequiredInput.bind(this));
      });
    }
  }

  // Removes errors from the form if the form is valid.
  removeFormError() {
    if (this.formTarget.length) {
      this.formErrorTarget.innerHTML = '';

      this.formTarget.querySelectorAll('input[required]').forEach((input) => {
        input.parentNode.classList.remove('has-error');
      });
    }
  }

  // Checks that all the form's required fields are filled. If we could check the
  // submission on the server-side, we could remove this function and controller completely.
  checkFormValidity() {
    if (this.formTarget.checkValidity()) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }

    // Clear the form error message
    this.removeFormError();
  }

  // Adds an error message to the form if the given field is empty.
  checkRequiredInput(event) {
    const input = event?.target

    if (input && input.value.trim() === "") {
      input.parentNode.classList.add('has-error');
    } else {
      input.parentNode.classList.remove('has-error');
    }
  }
}