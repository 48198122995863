import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {

  }

  // Toggles the 'expanded' class on a td if its collapser is clicked.
  // The expanded class is used to toggle visibility of the truncation content.
  toggleCellCollapse() {
    $(this.element).closest("td").toggleClass("expanded");
    $(this.element).siblings(".overflow-content").toggleClass("truncated");
  }
}
