import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["column"];
  columns = {"add": [], "remove": []};
  columnsChangedState = [];
  activeColumns = [];
  submitURL;

  // Function to handle the checkbox behavior in the dropdown. If a checkbox is checked, all of its children should
  // be checked too, but we also need to check if an indeterminate state should be set on the checked checkbox's parent(s).
  // Everything here is crafted specifically for the add column dropdown, but the logic could be moved into its own component
  // for easier resuse. Implementation adapted from https://codepen.io/chriscoyier/pen/JYyXjX
  setColumn(event) {
    var columnValue = event.currentTarget.value,
        checked = $(event.target).prop("checked"),
        container = $(event.target).closest("li"),
        self = this;

    container.find('input[type="checkbox"]').each(function() {
      $(this).prop({
        indeterminate: false,
        checked: checked
      });
      self.updateColumnsArray($(this).val(), checked);
    });

    this.checkSiblings(container, checked);
    this.updateColumnsArray(columnValue, checked);
  }

  // Function that is potentially called recursively to check the siblings of a checkbox. If all siblings are checked,
  // the parent should be checked too. If some siblings are checked, parent should be set to indeterminate.
  // Implementation adapted from https://codepen.io/chriscoyier/pen/JYyXjX
  checkSiblings(el, checked) {
    var parent = el.closest("ul").closest("li"),
        all = true;
        self = this;

    el.siblings().each(function() {
      let returnValue = all = ($(this).children(":not(ul)").find('input[type="checkbox"]').prop("checked") === checked);
      return returnValue;
    });

    if (all && checked) {
      parent.find('input[type="checkbox"]').each(function() {
        $(this).prop({
          indeterminate: false,
          checked: checked
        });
        self.updateColumnsArray($(this).val(), checked);
      });

      this.checkSiblings(parent, checked);

    } else if (all && !checked) {
      parent.children(":not(ul)").find('input[type="checkbox"]').each(function() {
        $(this).prop("checked", checked);
        self.updateColumnsArray($(this).val(), checked);
      });
      parent.children(":not(ul)").find('input[type="checkbox"]').prop("indeterminate", (parent.find('input[type="checkbox"]:checked').length > 0));
      this.checkSiblings(parent, checked);

    } else {
      el.parents("li").children(":not(ul)").find('input[type="checkbox"]').each(function() {
        $(this).prop({
          indeterminate: true,
          checked: false
        });
        self.updateColumnsArray($(this).val(), true);
      });
    }
  }

  updateColumnsArray(value, isChecked) {
    // Top level parents of nested columns don't have a value, don't bother adding to either add or remove
    if ([null, undefined, ''].includes(value)) {
      return;
    }

    const columnsArray = this.columns;
    const addKey = isChecked ? "add" : "remove";
    const removeKey = isChecked ? "remove" : "add";

    if (!columnsArray[addKey].includes(value)) {
      columnsArray[addKey].push(value);
    }

    columnsArray[removeKey] = $.grep(columnsArray[removeKey], function(item) {
      return item !== value;
    });
  }

  columnsToParams() {
    var params = [];
    for (var key in this.columns) {
      if (this.columns.hasOwnProperty(key)) {
        var values = this.columns[key];
        if (Array.isArray(values) && values.length > 0) {
          values.forEach(function(value) {
            params.push(key + "[]=" + encodeURIComponent(value));
          });
        }
      }
    }
    return params.join("&");
  }

  columnsFromTable() {
    var columns = []

    $("#query-builder-table-area table th").each(function() {
      columns.push($(this).data("column-value"))
    });

    return columns
  }

  // Function that sets the correct checkbox states in the dropdown based on the active columns.
  // Runs on controller connect. Probably not the most efficient way to do this, but it works.
  // The original code written for this function worked great but was a ghoulish nightmare,
  // so my pal ChatGPT helped me refactor to make things more readable (which isn't always perfect!).
  setColumnsFromActive() {
    const self = this;

    // Check if the value is a string representing an array, and if it is, parse the array out of it.
    function parseInputValue(value) {
      if (typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
        try {
          const parsedArray = JSON.parse(value);
          if (Array.isArray(parsedArray)) {
            return parsedArray;
          }
        } catch (error) {
          // If parsing fails, the value will remain as the original string
          return value;
        }
      }
      return value;
    }

    // Function to compare arrays
    function arraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false; // Check if the lengths are different
      return arr1.every((value, index) => value === arr2[index]); // Check each element
    }

    // Need to check if self.activeColumns includes the given value, and the value can either be an array or a string.
    function isActive(inputValue) {
      if (Array.isArray(inputValue)) {
        return self.activeColumns.some(item => Array.isArray(item) && arraysEqual(item, inputValue));
      }
      return self.activeColumns.includes(inputValue);
    }

    // Iterate over each checkbox
    $(this.element).find(".column-list-item input[type='checkbox']").each(function () {
      const $checkbox = $(this);
      const $childrenCheckboxes = $checkbox.parents("li").first().find("input[type='checkbox']");

      let childChecked = false;
      let allChildChecked = true;

      // Check the state of each child checkbox
      $childrenCheckboxes.each(function () {
        const inputValue = parseInputValue($(this).val());
        const checked = isActive(inputValue);

        if (checked) {
          childChecked = true;
        } else {
          allChildChecked = false;
        }
      });

      if (allChildChecked) {
        $checkbox.prop({ "checked": true, "indeterminate": false });
      } else if (childChecked) {
        $checkbox.prop({ "checked": false, "indeterminate": true });
      } else {
        $checkbox.prop({ "checked": false, "indeterminate": false });
      }
    });
  }

  // On submit, we want to submit an fetch request to the server to update the columns and to recieve the turbo stream.
  // Before, everything was appended to a URL: however, URLs have a limit on the number of characters they can have.
  // On response, we want to reset the columns hash's state and hide the dropdown.
  submit() {
    $(this.element).find("#submitColumns").addClass("loading");
    $("table").addClass("loading");

    let self = this

    fetch(this.submitURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Search-State-Token': $("#decoded-search-state-token").data("decoded-search-state-token")
      },
      body: JSON.stringify(this.columns)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      this.columns["add"].forEach((column) => {
        // Column already exist
        if (self.activeColumns.includes(column)) return

        let addedColumn = $(self.element).find(`input[value='${column}']`).closest("li")

        gaPush("dbplus_event",
          {
            "ga_event_location": $(addedColumn).data("ga-event-location") + "-add",
            "ga_text": $(addedColumn).data("ga-text"),
            "ga_request_id": response.headers.get("X-Request-Id")
          })
      })

      this.columns["remove"].forEach((column) => {
        // Column already does not exist
        if (!self.activeColumns.includes(column)) return

        let removedColumn = $(self.element).find(`input[value='${column}']`).closest("li")

        gaPush("dbplus_event",
          {
            "ga_event_location": $(removedColumn).data("ga-event-location") + "-remove",
            "ga_text": $(removedColumn).data("ga-text"),
            "ga_request_id": response.headers.get("X-Request-Id")
          })
      })

      return response.text();
    })
    .then(html => {
      Turbo.renderStreamMessage(html);

      this.columns["add"] = [];
      this.columns["remove"] = [];
      $("#addColumnDropdown").dropdown('toggle');

      // This doesn't need to be here, but is just in case the dropdown implementation changes.
      $(".loading").removeClass("loading");
    })
    .catch(error => {
      this.updateActiveColumns();
      // Handle error? Maybe show a message to the user?
    });
  }

  // Undoes the changes made in the dropdown
  cancel() {
    event.preventDefault()

    this.columns["add"] = []
    this.columns["remove"] = []

    this.updateActiveColumns()
    $("#addColumnDropdown").dropdown('toggle')
  }

  // Function to filter/search columns in the dropdown
  filterColumns(event) {
    let searchTerm = event.target.value.toLowerCase();
    let resultsFound = false;
    let noResultsMessage = $(this.element).find(".no-results");

    noResultsMessage.hide();

    $(this.columnTargets).each(function() {
      let col = $(this)
      let text = col.text().toLowerCase();

      if (text.includes(searchTerm)) {
        col.show();
        resultsFound = true;
      } else {
        col.hide();
      }
    });

    if (!resultsFound) {
      noResultsMessage.show();
    }
  }

  // This function is called by the column_options component. When a column is removed through that component,
  // we need to update activeColumns in this controller so that the removed column is unchecked in the dropdown.
  // Also called by cancel().
  updateActiveColumns() {
    this.activeColumns = this.columnsFromTable()
    this.setColumnsFromActive()
  }

  // Resets the state of the component in regard to what's hidden or shown.
  // The function to handle clearing the input is handled by its own component.
  clearSearch() {
    $(this.element).find(".no-results").hide();

    $(this.columnTargets).each(function() {
      $(this).show();
    });
  }

  connect() {
    this.submitURL = $(this.element).find("#submitColumns").data("submit-link")
    this.activeColumns = this.columnsFromTable()
    this.setColumnsFromActive()
    this.element[this.identifier] = this // this allows other controllers to access this controller instance
  }
}
