import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle"]

  connect() {

  }

  toggleOption() {
    $('table').addClass('loading');
    this.toggleTarget.form.classList.toggle("enabled")
    $(this.toggleTarget.form.parentElement).tooltip('hide');
    this.toggleTarget.form.requestSubmit()
  }
}
