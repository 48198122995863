// This controller is currently used for loading card page and table builder content.

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.load();
  }

  async load() {
    const response = await fetch(this.element.dataset.url, {
      headers: { "Accept": "text/vnd.turbo-stream.html" }
    });

    const html = await response.text();
    Turbo.renderStreamMessage(html);
    document.dispatchEvent(new CustomEvent("content-loader-controller-loaded"));
  }
}