import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="plus--side-panel--plus-card"
export default class extends Controller {

  connect() {
    // Convert certain links to open in new tab
    // [href] filter for all links with an href attribute
    // [href!=""] filter for all links that does not have a blank href
    // :not([href^="#"]) filter out all anchor links
    // :not([href^="javascript"]) filter out all javascript links
    $(this.element).find('a[href][href!=""]:not([href^="#"]):not([href^="javascript"])').each(function() {
      this.setAttribute("target", "_blank")
    })
  }
}
