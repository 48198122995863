// Converted jstree code into Stimulus controller. ChatGPT helped me out with this, so proceed with caution.
// Setups up jstree like how we were already doing in other spots with javascript element tags. The part where
// ChatGPT helped out was with the async loading of the jstree assets, since in all the spots were using it,
// we were always adding the script and css tags in the html file.

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  async connect() {
    await this.loadJsTreeAssets();
    this.initializeJsTree();
  }

  async loadJsTreeAssets() {
    await Promise.all([this.loadScript("/jstree/jstree.min.js"), this.loadStylesheet("/jstree/proton/style.min.css")]);
  }

  loadScript(src) {
    return new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${src}"]`)) {
        resolve(); // Script already loaded
        return;
      }

      const script = document.createElement("script");
      script.src = src;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  loadStylesheet(href) {
    return new Promise((resolve, reject) => {
      if (document.querySelector(`link[href="${href}"]`)) {
        resolve(); // Stylesheet already loaded
        return;
      }

      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = href;
      link.onload = resolve;
      link.onerror = reject;
      document.head.appendChild(link);
    });
  }

  initializeJsTree() {
    $(this.element).jstree({
      core: {
        multiple: false,
        themes: {
          name: "proton",
          responsive: true
        }
      }
    });

    this.element.addEventListener("changed.jstree", this.handleChange.bind(this));
  }

  handleChange(event, data) {
    const selected = data.node;
    if (selected && selected.a_attr["href"] !== "#") {
      const link = document.createElement("a");
      link.href = selected.a_attr["href"];
      link.target = "_blank";

      const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: false,
        cancelable: true
      });

      link.dispatchEvent(clickEvent);
    }
  }
}