import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (localStorage.getItem(`ftue_${this.element.id}_hidden`) || $(this.element).attr('disabled')) {
      return
    }

    $(this.element).popover()
    $(this.element).popover('show')

    let popover_id = $(this.element).attr('aria-describedby')

    $("#" + popover_id).find('.popover-close').on('click', () => {
      this.remove()
    })
  }

  remove() {
    $(this.element).popover('hide')
    $(this.element).popover('disable')

    localStorage.setItem(`ftue_${this.element.id}_hidden`, true)
  }

  disconnect() {
    $(this.element).popover('dispose')
  }
}