import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="decoded-state"
export default class extends Controller {
  static targets = ["token"]
  static values = {
    fetchUrl: String
  }

  connect() {
    if (!$(this.tokenTarget).data("decoded-search-state-token")) {
      fetch(this.fetchUrlValue, {
        headers: {
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
          "Accept": "text/vnd.turbo-stream.html"
        }
      })
        .then(response => response.text())
        .then(text => Turbo.renderStreamMessage(text))
    }

    document.addEventListener("turbo:before-fetch-request", (event) => {
      event.detail.fetchOptions.headers["Search-State-Token"] = $(this.tokenTarget).data("decoded-search-state-token")
    })
  }
}
