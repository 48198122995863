import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["contentHeader", "name", "nameResizer"];
  originalName

  connect() {
    this.handleHeaderHover()

    window.addEventListener("resize", this.setTableNameMaxWidth.bind(this));

    // name target is only present when the query has been saved and renamed
    if (this.hasNameTarget) {
      this.adjustInputWidth()
      this.nameTarget.addEventListener("beforeinput", this.handleInputKeyDown.bind(this))
      this.nameTarget.addEventListener("focus", this.handleInputFocus.bind(this))
      this.nameTarget.addEventListener("blur", this.handleInputBlur.bind(this))
    }

    // Resize observer to adjust the table name width when the table area is resized
    // (e.g. when the filter panel or side nav is opened/closed)
    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        this.setTableNameMaxWidth();
      }
    });

    this.resizeObserver.observe(this.contentHeaderTarget);
  }

  disconnect() {
    window.removeEventListener("resize", this.setTableNameMaxWidth.bind(this));

    if (this.hasNameTarget) {
      this.nameTarget.removeEventListener("beforeinput", this.handleInputKeyDown.bind(this))
      this.nameTarget.removeEventListener("focus", this.handleInputFocus.bind(this))
      this.nameTarget.removeEventListener("blur", this.handleInputBlur.bind(this))
    }

    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.contentHeaderTarget);
    }
  }

  handleHeaderHover() {
    $(this.element).on("mouseenter", function() {
      $(this).css("top", "0px")
    })

    $(this.element).on("mouseleave", function() {
      let self = this
      let $tableWrapper = $("#query-builder-table-area .table-container")

      if ($tableWrapper.length && $tableWrapper.scrollTop() !== 0) {
        $(self).css("top", `-${$(self).outerHeight()}px`)

        $(self).find(".dropdown").each(function() {
          if ($(this).hasClass("show")) {
            $(this).dropdown("toggle");
          }
        })
      } else {
        $(self).css("top", "0px")
      }
    })
  }

  setTableNameMaxWidth() {
    // Get the inner width of the header
    var clientWidth = $(this.contentHeaderTarget).width()
    var rightClientWidth = 0

    // Get the width of the content in the right side of the header
    $(this.contentHeaderTarget).find(".right-header-content").children().each(function() {
      rightClientWidth += $(this).outerWidth()
    })

    // Calculate the width that the left side of the header can occupy
    var width = clientWidth - rightClientWidth

    // Set the width of the query-name-container and text input to be the length of the query name.
    // This fixes a bug where when if the name is truncated and you click into it to change it, resizing
    // the window then introduces clipping with the "create a new table" dropdown.
    var queryNameWidth = $(this.contentHeaderTarget).find(".query-name-resizer").outerWidth()

    $(this.contentHeaderTarget).find(".query-name-container").css("width", `${queryNameWidth}px`)
    $(this.contentHeaderTarget).find(".query-name-resizer").css("max-width", `${width}px`)
    this.nameTarget.style.width = queryNameWidth + 'px';
  }

  // If user presses enter, blur the input to submit change
  handleInputKeyDown(event) {
    switch (event.inputType) {
      case "insertLineBreak":
        this.nameTarget.blur()
      default:
        break
    }
  }

  // When the input is focused, hide the span, adjust the input width,
  // and store the original name.
  handleInputFocus() {
    this.nameResizerTarget.style.visibility = "hidden"
    this.adjustInputWidth()
    this.setOriginalName()
  }

  // When the input is blurred, show the span, and if the name is empty,
  // set an "Untitled" name and adjust the input width. Afterwards, update the table name.
  handleInputBlur() {
    this.nameResizerTarget.style.visibility = "visible"
    this.nameTarget.value = this.nameTarget.value.trim()
    this.nameResizerTarget.innerHTML = this.nameTarget.value // just to set the trimmed value here too

    if (this.nameTarget.value == "") {
      this.nameTarget.value = "Untitled"
      this.nameResizerTarget.innerHTML = "Untitled"
      this.nameResizerTarget.classList.add("query-untitled")
    } else if (this.nameTarget.value != "Untitled") {
      this.nameResizerTarget.classList.remove("query-untitled")
    }

    this.adjustInputWidth() // adjust width if name changed to "Untitled" or if trailing whitespace was removed
    this.updateTableName()
  }

  // Adjust the input width to match the span width while typing
  adjustInputWidth() {
    const value = this.nameTarget.value.replace(/ /g, '&nbsp;');

    this.nameResizerTarget.innerHTML = value

    // Get the width of the span (which now has the input text)
    const newWidth = this.nameResizerTarget.offsetWidth;

    // Apply the width to the input
    this.nameTarget.style.width = newWidth + 'px';

    this.setTableNameMaxWidth()
  }

  // On focus of the table name, we want to store the original name before changing in case of an error
  setOriginalName() {
    this.originalName = this.nameTarget.value
  }

  // Update the table name in the database by sending a POST request with the new name.
  // A turbo stream is returned to update the name and the save button state.
  updateTableName() {
    if (this.nameTarget.value == this.originalName) return;

    fetch("/plus/explore/change_table_name", {
      method: 'POST',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html', // TODO: remove this? See change_table_name in controller first
        'Content-Type': 'application/x-www-form-urlencoded', // simulate rails form submission
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Search-State-Token': $("#decoded-search-state-token").data("decoded-search-state-token")
      },
      body: new URLSearchParams({
        name: this.nameTarget.value,
      }).toString()
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return response.text();
    })
    .then(html => {
      Turbo.renderStreamMessage(html);
    })
    .catch(error => {
      // If error, set name back to what it was (though we should add more checking here,
      // depending on the error the name may or may not have saved). Maybe also show a message?
      this.nameTarget.value = this.originalName
    });
  }
}