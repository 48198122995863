import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tableContainer", "table", "header", "columnExpander"]
  static values = {
    addedColumn: Boolean
  }

  connect() {
    if (this.hasTableTarget) {
      this.setNestedColumnTops()
      this.handleTableWrapperScroll();

      if (this.addedColumnValue) {
        this.tableContainerTarget.scrollLeft += this.tableTarget.offsetWidth
      }
    }

    $(".loading").removeClass("loading")
  }

  disconnect() {
    $(document).off("side-panel:close")
    $(document).off("side-panel:opened")
  }

  // When the rows per page value is changed, add the loading state to the table and
  // then submit the control form to reload the table with the new rows per page amount
  setRowsPerPage(event) {
    $(this.tableTarget).addClass('loading')
    event.target.form.requestSubmit()
  }

  handleTableWrapperScroll() {
    var $header = $(".content-header-container");
    var $tableArea = $(".table-area-wrapper");
    var navHeight = $("#main-nav").outerHeight() || 0;
    var headerHeight = $header.outerHeight();

    // Hide dropdowns in both the table area and header on scroll
    $tableArea.add($header).find(".dropdown").each(function() {
      if ($(this).hasClass("show")) {
        $(this).dropdown("toggle");
      }
    });

    // If the table wrapper isn't scrolled to the top...
    if (this.tableContainerTarget.scrollTop !== 0) {
      $header.css('top', `-${headerHeight}px`);
      $tableArea.css({
        'margin-top': '0px',
        'height': `calc(100vh - ${navHeight}px)`
      });

      // Adjust FTUE tooltip on "Add Column" dropdown
      $tableArea.find(".db-popover").each(function() {
        let popover = $(this).attr("aria-describedby")
        if ($(`#${popover}`).attr('x-placement') == 'top') {
          $(`#${popover}`).css("top", `-${headerHeight}px`)
        } else if ($(`#${popover}`).attr('x-placement') == 'bottom') {
          $(`#${popover}`).css("top", `0px`)
        }
      });
    } else {
      $header.css('top', '0px');
      $tableArea.css({
        'margin-top': `${headerHeight}px`,
        'height': `calc(100vh - ${navHeight}px - ${headerHeight}px)`
      });

      // Adjust FTUE tooltip on "Add Column" dropdown
      $tableArea.find(".db-popover").each(function() {
        let popover = $(this).attr("aria-describedby")
        if ($(`#${popover}`).attr('x-placement') == 'top') {
          $(`#${popover}`).css("top", `0px`)
        } else if ($(`#${popover}`).attr('x-placement') == 'bottom') {
          $(`#${popover}`).css("top", `${headerHeight}px`)
        }
      });
    }
  }

  // Nested columns can't have a top of 0 since that
  // will overlap them with their parent column header above.
  setNestedColumnTops() {
    var thead_trs = $(this.element).find("thead tr");

    // If there are multiple rows in the thead, there are nested columns
    if (thead_trs.length > 1) {
      thead_trs.each(function() {
        var top = 0;

        $(this).prevAll().each(function() {
          top += $(this).outerHeight();
        });

        $(this).find("th").each(function() {
          $(this).css("top", top + "px");
        });
      });
    }
  }

  scrollHeaderIntoView(event) {
    let header = event.currentTarget

    $(document).one("side-panel:opened", (event) => {
      header.scrollIntoView({ inline: 'center', block: 'nearest', behavior: 'auto' })
    })
  }

  highlight(event) {
    let cur = $(event.currentTarget)
    let tbody = $(event.currentTarget).closest("tbody")
    let table = $(this.tableTarget)

    let columnParentKey = JSON.stringify(cur.data("column-parent-key"))

    tbody.find(`td[data-row-index=${cur.data("row-index")}][data-column-parent-key=null]`).addClass("highlight")
    cur.siblings(`td[data-row-index=${cur.data("row-index")}][data-column-parent-key='${columnParentKey}']`).addClass("highlight")
    cur.addClass("highlight")

    // Only reduce opacity when hovering over nested columns
    if (columnParentKey != "null") {
      // Reduce opacity to nested data cells
      table.find(`td:not([data-column-parent-key='${columnParentKey}']):not([data-column-parent-key=null])`).addClass("lowlight")

      // Reduce opacity to the nested column headers. e.g. Drug Name
      table.find(`th[data-column-parent-key]:not([data-column-parent-key='${columnParentKey}'])`).each(function() {
        $(this).addClass("lowlight")

        // Reduce opacity to the top level nested column header. e.g. Related Drugs List
        let parentKey = JSON.stringify($(this).data("column-parent-key"))
        table.find(`th[data-column-key='${parentKey}']`).addClass("lowlight")
      })
    }

    table.addClass("highlight")
  }

  unhighlight(event) {
    $(this.tableTarget).find(".highlight").removeClass("highlight")
    $(this.tableTarget).find(".lowlight").removeClass("lowlight")
  }

  columnExpanderTargetConnected(element) {
    this.addExpanderHeight(element)

    let resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        this.addExpanderHeight(entry.target)
      })
    })

    resizeObserver.observe(element)
    element.expandingResizeObserver = resizeObserver
  }

  addExpanderHeight(expander) {
    let expanderHeight = $(expander).outerHeight()
    $(expander).parent().find("table tr:last-of-type td .nested-cell-data").each(function () {
      $(this).css("padding-bottom", "") // Clear any inline padding
      let basePaddingBottom = parseInt($(this).css("padding-bottom"))
      $(this).css("padding-bottom", expanderHeight + basePaddingBottom)
    })
  }

  columnExpanderTargetDisconnected(element) {
    element.expandingResizeObserver.disconnect()
  }

  tableTargetConnected(element) {
    this.alignNestedColumnHeaderRowHeight()
  }

  alignNestedColumnHeaderRowHeight() {
    let nestedColumnHeaderRows = $(this.tableTarget).find(".nested-column-header-row")

    if(nestedColumnHeaderRows.length == 0) return

    let nestedColumnHeights = nestedColumnHeaderRows.map(function () {
      return this.getBoundingClientRect().height
    })

    // Setting the minimum height of nested columns to be 60px
    nestedColumnHeights.push(60)

    let tallestNestedSubColumnHeaderRow = Math.max.apply(null, nestedColumnHeights)

    nestedColumnHeaderRows.map(function() {
      $(this).innerHeight(tallestNestedSubColumnHeaderRow)
    })
  }
}
