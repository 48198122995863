import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tooltip"];
  static values = {
    url: String
  }

  connect() {
    this.element.addEventListener("mouseup", this.handleMouseUpEvent.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("mouseup", this.handleMouseUpEvent.bind(this));
  }

  // Handle mouse clicks on the saved item. Needs to be added as an event listener so that middle mouse clicks can be detected
  handleMouseUpEvent(event) {
    const savedItemActions = this.element.querySelector('.saved-item-actions');

    if (savedItemActions && (savedItemActions === event.target || savedItemActions.contains(event.target))) {
      // If the click is on the dropdown or its children, do nothing
      return;
    }

    if (event.button == 1) {
      // Middle mouse click
      window.open(this.urlValue, '_blank')
    } else if (event.button == 0) {
      // Left mouse click
      if (event.ctrlKey || event.metaKey) {
        // Ctrl (or Cmd) + left click
        window.open(this.urlValue, '_blank');
      } else {
        $(this.tooltipTarget).tooltip('hide');

        // Regular left click
        Turbo.visit(this.urlValue);
      }
    }
  }
}
