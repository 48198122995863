import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {

  }

  focusSelected() {
    $('#entrypoint_dropdown_button').dropdown('toggle');
    $("#entrypoint_dropdown_button").addClass("loading");
    $('.default-content').hide();
    $('.loading-table-wrapper').addClass('show');

    setTimeout(()=> {
      $(this.element)[0].requestSubmit();
    }, 800);
  }

  newFocusTab(event) {
    // The functionality in here might get refactored out once we start adding functional table tabs into DB+
    let entrypointInput = event.currentTarget

    window.open(`/plus/explore?focus=${entrypointInput.value}`)
    entrypointInput.checked = false
  }
}
